import Web3 from "web3";
import window from "global";
import exactMath from "exact-math";
import erc20Abi from "../contracts/erc20.abi";

import bridgeAbi from "../contracts/bridge05.json";
import bridge07Abi from "../contracts/bridge07.json";
import {
  calculateBalanceBigNumber,
  calculateBalanceBigNumberTron,
  calculateBalanceSend,
} from "./utils";
import { BigNumber } from "bignumber.js";
import chainHost from "../contracts/chainHost";
import { helpers } from "./helpers";

import { NETWORK_LIST, STATUS } from "../constants";
import { IMAGE_URL, MODE, SWAP_SMART_CONTRACT, BRIDGE_07_SMART_CONTRACT, RPC } from "../_configs";
import { CHAIN_ID } from "../constants/chainId";

import { extensionName } from "../constants/values";

export default class WalletExtensionUtils {
  constructor(ex) {
    this.web3 = null;
    this.tronWeb = null;
    this.extension = null;

    this.isWrongNetwork = false;
    this.extensionName = ex;
    this.network = "";
  }

  async connect(currentInputNetWork) {
    console.log("CONNECT MODE==>", MODE);
    if (this.extensionName === extensionName.binanceExtension) {
      if (window.BinanceChain) {
        this.extension = window.BinanceChain;
        this.web3 = new Web3(window.BinanceChain);

        try {
          const envCheck = !(
            window.BinanceChain.chainId ===
              Web3.utils.numberToHex(CHAIN_ID.BSC[MODE]) ||
            window.BinanceChain.chainId ===
              Web3.utils.numberToHex(CHAIN_ID.ETH[MODE])
          );

          if (envCheck) {
            this.isWrongNetwork = true;
            return;
          }

          this.network = currentInputNetWork;

          await window.BinanceChain.enable();
          const addresses = await this.web3.eth.getAccounts();
          this.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          this.web3 = null;
        }
      } else throw new Error("Detect Binance Extension failed!");

      return window.BinanceChain.chainId;
    } else if (
      this.extensionName === extensionName.metamask ||
      this.extensionName === extensionName.trustWallet
    ) {
      if (window.ethereum) {
        // console.log("get window.ethereum");
        this.extension = window.ethereum;
        this.web3 = new Web3(window.ethereum);

        // console.log("window.ethereum enable");
        await window.ethereum.enable();

        //check current network
        let envCheck;
      
        if (currentInputNetWork !== 'ETH' && currentInputNetWork !== 'BSC' && currentInputNetWork !== 'KCC'  ) {
          this.isWrongNetwork = true;
          return;
        }
     
        if (
          typeof currentInputNetWork === "string" &&
          currentInputNetWork.toUpperCase() === 'ETH'
        ) {
          //connect with eth
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID['ETH'][MODE]) ||
            window.ethereum.chainId === CHAIN_ID['ETH'][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID.ETH[MODE] ||
            (!window.ethereum.chainId && !window.ethereum.networkVersion)
          );
        } else if(typeof currentInputNetWork === "string" &&
        currentInputNetWork.toUpperCase() === 'BSC') {
          //connect with bsc
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID['BSC'][MODE]) ||
            window.ethereum.chainId === CHAIN_ID['BSC'][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID['BSC'][MODE]
          );
        } else if(typeof currentInputNetWork === "string" &&
          currentInputNetWork.toUpperCase() === 'KCC'){
            debugger
          //connect with kcs
          envCheck = !(
            window.ethereum.chainId ===
            Web3.utils.numberToHex(CHAIN_ID['KCC'][MODE]) ||
            window.ethereum.chainId === CHAIN_ID['KCC'][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID['KCC'][MODE]
          );
        }
        if (envCheck) {
          this.isWrongNetwork = true;
          return;
        }

        try {
          this.network = currentInputNetWork;
          const addresses = await this.web3.eth.getAccounts();
          this.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          this.web3 = null;
        }
      } else throw new Error("Detect Wallet failed!");

      return window.ethereum.chainId;
    } else if (this.extensionName === extensionName.tronLink) {
      if (currentInputNetWork !== 'TRON') {
        this.isWrongNetwork = true;
        return;
      }

      if (window.tronWeb) {
        if (window.tronWeb.fullNode.host === chainHost.tronLocalhost) {
          this.tronWeb = null;
          this.isWrongNetwork = true;
          return;
        }

        this.tronWeb = window.tronWeb;

        const isWrongNetwork =
          MODE === "TESTNET"
            ? window.tronWeb.fullNode.host !== chainHost.tronTestnet
            : window.tronWeb.fullNode.host !== chainHost.tronMainnet;

        if (isWrongNetwork) {
          this.isWrongNetwork = true;
          return;
        }
        this.network = currentInputNetWork;

        this.address = window.tronWeb.defaultAddress.base58;
      } else throw new Error("Detect Wallet failed!");
    }
  }

  accountsChanged(callback) {
    // const this = this;
    if (this.extension) {
      this.extension.on("accountsChanged", function (accounts) {
        this.address = accounts[0];
        callback(accounts[0]);
      });
    }
  }

  chainChanged(callback) {
    // const this = this;
    // debugger;
    this.extension.on("chainChanged", function (chainId) {
      // console.log("chainId==>", chainId);
      this.extension = window.ethereum;
      this.web3 = new Web3(window.ethereum);
      callback(chainId);
    });
  }

  isConnected() {
    return this.web3 !== null;
  }
  checkWrongNetwork() {
    return this.isWrongNetwork;
  }

  //get current chain of extension
  getCurrentChainId() {
    return Number(window.ethereum.networkVersion);
  }

  async getTokenBalance({ tokenAddress, decimal }) {
    try {
      if (this.network === 'TRON') {
        let contract = await this.tronWeb.contract().at(tokenAddress);
        const tokenBalance = await contract.balanceOf(this.address).call();
        // debugger
        return exactMath.div(Number(tokenBalance), exactMath.pow(10, decimal));
      }
      if (this.network === 'ETH' || this.network === 'BSC' || this.network === 'KCC' ) {
        const tokenContract = new this.web3.eth.Contract(
          erc20Abi,
          tokenAddress
        );

        const tokenBalance = await tokenContract.methods
          .balanceOf(this.address)
          .call();
        // debugger
        return exactMath.div(Number(tokenBalance), exactMath.pow(10, decimal));
      }
    } catch (error) {
      console.log(error);
    }

    return 0;
  }


  //call approve smart contract use token f user
  async approveToken({ tokenContractAddress, spenderAddress, amount, decimal, inputNetWork },callback ) {

    if (!tokenContractAddress ||!spenderAddress ||!amount ||inputNetWork !== this.network) {
      callback({ status: STATUS.APPROVE_FAILS});
      return;
    }

    callback({status: STATUS.APPROVING,});
    //approve token erc20
    if (this.network.toUpperCase()=== 'ETH' || this.network.toUpperCase() === 'BSC' || this.network.toUpperCase() === 'KCC') {

      amount = calculateBalanceBigNumber(amount, decimal || 18);
      try {
        const tokenContract = new this.web3.eth.Contract(
          erc20Abi,
          tokenContractAddress
        );
        callback({
          status: STATUS.APPROVING,
        });
        const amountInHex = "0x" + amount.toString(16);
        // console.log(amountInHex);
        await tokenContract.methods
          .approve(spenderAddress, amountInHex)
          .send({ from: this.address });
        // }
        callback({
          status: STATUS.APPROVED,
        });
      } catch (error) {
        callback({
          status: STATUS.APPROVE_FAILS,
        });
        console.log(error);
      }
    }

    //approve token trc20 tron
    if (this.network.toUpperCase() === 'TRON') {
   
      try {
 
        const contract = await this.tronWeb.contract().at(tokenContractAddress);
        // console.log(contract);

        amount = calculateBalanceBigNumberTron(amount, decimal || 18);

        let result = await contract.approve(spenderAddress, amount).send();

        let cond = true;
        let count = 0
        while (cond || count >=4) {
          const checkAllowance = await this.getAllowanceTrx(
            tokenContractAddress,
            spenderAddress
          );
          if (new BigNumber(checkAllowance).gte(new BigNumber(amount))){
            cond = false;
            callback({
              status: STATUS.APPROVED,
              txID: result,
            });
          }
            
          count ++
          await new Promise((r) => setTimeout(r, 3000));
        }

       
      } catch (error) {
        callback({
          status: STATUS.APPROVE_FAILS,
        });
        // console.log(error);
      }
    }
  }


/**
 * 
 * @param {*} param
 * @param {*} callback 
 */
  async swap( { tokenAddress, amount, toAddress, decimal, fee, desNetwork, bridgeContractAddress },
    callback){
      
      callback({
        status: STATUS.APPROVING,
      });
     
        const BRIDGE = `${this.network}_NETWORK`.toUpperCase() 

        if(this.network=='BSC' || this.network=='ETH'|| this.network == 'KCC'){
          const contract = new this.web3.eth.Contract( 
            bridgeAbi,
            bridgeContractAddress,
          );
      
          // const swapFee = this.calculateSendAmount(Number(fee));
          fee = this.calculateSendAmount(Number(fee));
          // amount = calculateBalanceBigNumber(amount, decimal || 18).toString();
      
          amount = calculateBalanceSend(amount);
          const amountInHex = "0x" + amount.toString(16);
          try {
            console.log(tokenAddress, desNetwork, toAddress, amountInHex);
            const executeSwapResult = await contract.methods
              .swap(tokenAddress, desNetwork, toAddress, amountInHex )
              .send({ from: this.address, value: fee })
              .on("transactionHash", (hash) => {
                callback({
                  status: STATUS.SWAP_SUBMITTING,
                  txID: hash,
                });
              })
              .on("error", (error) => {
                console.log(error);
                callback({
                  status: STATUS.SWAP_FAILS,
                });
              })
              .then((receipt) => {
                if (receipt.status === true) {
                  callback({
                    status: STATUS.SWAP_SUCCESS,
                    txID: receipt.transactionHash,
                  });
                } else callback({ status: STATUS.SWAP_FAILS });
              })
              .catch((err) => {
                console.log(err);
                callback({ status: STATUS.SWAP_FAILS });
              });
            // return executeSwapResult;
          } catch (e) {
            console.error(e.message);
            callback({
              status: STATUS.SWAP_FAILS,
            });
            // return e.message;
          }
        }
          //code here
        if(this.network=='TRON') {
          try {
            // const contract = await this.tronWeb.contract(bridgeAbi, SWAP_SMART_CONTRACT.TRX_NETWORK[MODE]);  // for deploy proxy
      
            let contract = await this.tronWeb
              .contract()
              .at(bridgeContractAddress);
            amount = calculateBalanceBigNumberTron(amount, decimal || 18);
      
            // const swapFee = NETWORK_LIST.find(e=>e.id === 'TRON')
            fee = calculateBalanceBigNumberTron(fee, 6);
            const result = await contract
              .swap(tokenAddress, desNetwork, toAddress, amount )
              .send({ callValue: fee });
            // debugger
            if (result) {
              callback({
                status: STATUS.SWAP_SUBMITTING,
                txID: result,
              });
      
              await new Promise((r) => setTimeout(r, 5000));
      
              const tx = await this.tronWeb.trx.getTransaction(result);
              if (tx && tx.ret && tx.ret.length > 0 && tx.ret[0].contractRet) {
                if (tx.ret[0].contractRet === "SUCCESS") {
                  callback({
                    status: STATUS.SWAP_SUCCESS,
                    txID: result,
                  });
                } else {
                  callback({
                    status: STATUS.SWAP_FAILS,
                  });
                }
              }
            } else {
              callback({
                status: STATUS.SWAP_FAILS,
              });
            }
      
            //  console.log(result);
          } catch (error) {
            console.log(error);
            callback({
              status: STATUS.SWAP_FAILS,
            });
          }
        }

  }
  /**
   * 
   * @param {*} param0 
   * @param {*} callback 
   * @returns 
   */

  //get current account
  getCurrentAddress() {
    return this.address;
  }

  //get current network connect
  getCurrentNetWork() {
    return this.network;
  }

  calculateSendAmount(amount) {
    return this.web3.utils.toWei(amount.toString(), "ether");
  }

  fromWei(amount) {
    return this.web3.utils.fromWei(amount.toString(), "ether");
  }

  async getBalanceAccount() {
    const symbol = NETWORK_LIST.find((e) => e.id === this.network).currency;

    try {
      let balance = 0;
      if (this.network === 'BSC' || this.network === 'ETH' || this.network == 'KCC') {
        balance = await this.web3.eth.getBalance(this.address);
       switch (this.network ) {
         case "BSC":
          return (
            helpers.formatNumberDownRoundWithExtractMax(
              this.fromWei(Number(balance)),
              6
            ) + ` ${symbol}`
          );
        
           case "ETH":
            return (
              helpers.formatNumberDownRoundWithExtractMax(
                this.fromWei(Number(balance)),
                8
              ) + ` ${symbol}`
            );
            case "KCC":
            return (
              helpers.formatNumberDownRoundWithExtractMax(
                this.fromWei(Number(balance)),
                4
              ) + ` ${symbol}`
            );
         default:
           break;
       }
       
        return balance + ` ${symbol}`
      } else if (this.network === 'TRON') {
        balance = await this.tronWeb.trx.getBalance(this.address);

        return (
          helpers.formatNumberDownRoundWithExtractMax(balance / 10 ** 6, 4) +
          ` ${symbol}`
        );
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  //add function get getAllowance
  async getAllowance(tokenAddress, contractAddress) {
    if (this.network === 'BSC' || this.network === 'ETH' || this.network == 'KCC') {
      const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

      const allocationNumber = await tokenContract.methods
        .allowance(this.address, contractAddress)
        .call();
      const decimal = await tokenContract.methods.decimals().call();
      return new BigNumber(allocationNumber.toString())
        .dividedBy(10 ** Number(decimal))
        .toString();
    }
    if (this.network === 'TRON') {
      let contract = await this.tronWeb.contract().at(tokenAddress);

      let decimal = await contract.methods.decimals().call();
      let allocationNumber = await contract.methods
        .allowance(this.address, contractAddress)
        .call();
      decimal = decimal.toString();
      allocationNumber = new BigNumber(allocationNumber.toString())
        .dividedBy(10 ** Number(decimal))
        .toString();
      return allocationNumber;
    }
  }

  async getAllowanceTrx (tokenAddress, contractAddress){
    try {
      let contract = await this.tronWeb.contract().at(tokenAddress);

    let allocationNumber = await contract.methods
      .allowance(this.address, contractAddress)
      .call();
    
    return allocationNumber.toString();
    } catch (error) {
      console.log(error);
      return 0
    }
    
  
  }

  async getInfo() {

    const BRIDGE = `${this.network}_NETWORK`.toUpperCase() ;

    if (this.network === 'BSC' || this.network === 'ETH'|| this.network === 'KCC') {
      try {
        console.log(
          "bridge contract address: ",
          SWAP_SMART_CONTRACT[BRIDGE][MODE]
        );
        const bridgeContract = new this.web3.eth.Contract(bridgeAbi,SWAP_SMART_CONTRACT[BRIDGE][MODE]);

        let promises = [];
        promises.push(bridgeContract.methods.info().call({ from: this.address }));
        promises.push(bridgeContract.methods.infoEX().call({ from: this.address }));

        const results = await Promise.all(promises)
        const info = results[0];
        const infoEx = results[1];

        let tempToken = [];
        for (let i = 0; i < info[1].length; i++) {
          const decimals = Number(info[4][i]);
          const symbol = info[3][i];

          //infoEx
          const balance = infoEx[0][i];
          const fee = exactMath.div(Number(infoEx[1][i]), exactMath.pow(10, 18));

          const swapped = exactMath.div( Number(infoEx[3][i]),exactMath.pow(10, decimals));

          const limit = exactMath.div( Number(infoEx[2][i]),exactMath.pow(10, decimals));

          const addressLimit = exactMath.div( Number(infoEx[4][i]),exactMath.pow(10, decimals));
          const addressSwapped = exactMath.div( Number(infoEx[5][i]),exactMath.pow(10, decimals));
          tempToken.push({
            contractAddress: info[1][i],
            networks: info[2][i],
            symbol,
            decimals,
            paused: info[5][i],
            balance: balance,
            fee,
            limit,
            swapped,
            addressLimit,
            addressSwapped,
            image: `${IMAGE_URL}` + symbol + ".png",
            bridgeContractAddress: SWAP_SMART_CONTRACT[BRIDGE][MODE],
          });
        }

        if (this.network === 'BSC') {
          const bridge07Contract = new this.web3.eth.Contract(bridge07Abi,BRIDGE_07_SMART_CONTRACT[BRIDGE][MODE]);
          const swapPairCount = await bridge07Contract.methods.swapPairCount().call({ from: this.address });
          promises = [];

          for (let i = 0; i < swapPairCount; i = i + 30) {
            let startIndex = i;
            let count = 30;

            if (swapPairCount < startIndex + count) {
              count = swapPairCount - startIndex;
            }

            promises.push(bridge07Contract.methods.infoRange(startIndex, count).call({ from: this.address }));
            promises.push(bridge07Contract.methods.infoEXRange(startIndex, count).call({ from: this.address }));
          }

          if (promises.length > 0) {
            const results = await Promise.all(promises);

            for (let i = 0; i < promises.length; i = i + 2) {
              const infoRange = results[i];
              const infoExRange = results[i+1];

              for (let i = 0; i < infoRange[1].length; i++) {
                const decimals = Number(infoRange[4][i]);
                const symbol = infoRange[3][i];

                //infoEx
                const balance = infoExRange[0][i];
                const fee = exactMath.div(Number(infoExRange[1][i]), exactMath.pow(10, 18));

                const swapped = exactMath.div( Number(infoExRange[3][i]),exactMath.pow(10, decimals));

                const limit = exactMath.div( Number(infoExRange[2][i]),exactMath.pow(10, decimals));

                const addressLimit = exactMath.div( Number(infoExRange[4][i]),exactMath.pow(10, decimals));
                const addressSwapped = exactMath.div( Number(infoExRange[5][i]),exactMath.pow(10, decimals));
                tempToken.push({
                  contractAddress: infoRange[1][i],
                  networks: infoRange[2][i],
                  symbol,
                  decimals,
                  paused: infoRange[5][i],
                  balance: balance,
                  fee,
                  limit,
                  swapped,
                  addressLimit,
                  addressSwapped,
                  image: `${IMAGE_URL}` + symbol + ".png",
                  bridgeContractAddress: BRIDGE_07_SMART_CONTRACT[BRIDGE][MODE],
                  srcNetwork: 'BSC2',
                });
              }
            }
          }
        }

        // debugger
        console.log(tempToken);
        return {
          paused: info[0],
          tokens: tempToken.filter((item) => item.paused == false),
        };
      } catch (error) {
        console.log(error);
        return { paused: false, tokens: [] };
      }
    }

    if (this.network === 'TRON') {
      try {
        // console.log(SWAP_SMART_CONTRACT[BRIDGE][MODE]);

        const trcBrideContract = await this.tronWeb.contract(bridgeAbi, SWAP_SMART_CONTRACT[BRIDGE][MODE]);     //for deploy proxy
        // const data = await trcBrideContract.info().call({ _isConstant: true });     //for deploy proxy
        
        // let trcBrideContract = await this.tronWeb.contract().at(bridgeAbi, SWAP_SMART_CONTRACT[BRIDGE][MODE]);
      

        const infoReq =   trcBrideContract.info().call();
     
        const infoExReq =  trcBrideContract.infoEX().call();
       
        
        
      
        
        const results = await Promise.all([infoReq, infoExReq])
      
        const info = results[0]
        const infoEx  = results[1]
        
        let tempToken = [];
        for (let i = 0; i < info[1].length; i++) {

          const decimals = Number(info[4][i].toString());
          const symbol = info[3][i].toString();

           //infoEx
          const balance = infoEx[0][i].toString();
          const fee = exactMath.div(Number(infoEx[1][i].toString()),exactMath.pow(10, 6) );
          const limit = exactMath.div(Number(infoEx[2][i].toString()),exactMath.pow(10, decimals));
          const swapped = exactMath.div(Number(infoEx[3][i].toString()),exactMath.pow(10, decimals));
          const addressLimit = exactMath.div(Number(infoEx[4][i].toString()),exactMath.pow(10, decimals));
          const addressSwapped = exactMath.div(Number(infoEx[5][i].toString()),exactMath.pow(10, decimals));
          // debugger
          tempToken.push({
            contractAddress: this.tronWeb.address.fromHex(info[1][i]),
            networks: info[2][i],
            symbol,
            decimals,
            paused: info[5][i],
            balance: balance,
            fee,
            limit,
            swapped,
            addressLimit,
            addressSwapped,
            image: `${IMAGE_URL}` + symbol + ".png",
          });
          // tokens[data[1][i]] =
        }
       
        // console.log(tempToken);
        return {
          paused: info[0],
          tokens: tempToken.filter((item) => item.paused == false),
        };
      } catch (error) {
        console.log(error);
        return { paused: false, tokens: [] };
      }
    }
  }

  async isContract(address, network) {
    if (network === 'KCC') {
      const web3 = new Web3(RPC.KCC_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== '0x';
    } else if (network === 'BSC') {
      const web3 = new Web3(RPC.BSC_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== '0x';
    }
  }
}
