import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Button } from 'react-bootstrap';
import AmountInputPanel from '../../component/AmountInputPanel';
import AssetPanel from '../../component/AssetPanel';
import NetworkInputPanel from '../../component/NetworkInputPanel';
import Card from '../../component/Card';
import Column from '../../component/Column';
import Container from '../../component/Container';
import Row from '../../component/Row';
import { NETWORK_LIST, ACTION_CONST, LIMIT_VALUE } from '../../constants';

import StepModal from './StepModal';
import DestinationInput from '../../component/DestinationInput';
import { BigNumber } from "bignumber.js";
const defaultInputNetwork = NETWORK_LIST.find(e => e.defaultInput == true);
const defaultOutputNetwork = NETWORK_LIST.find(e => e.defaultOutPut == true);

const BridgePortalPage = (props) => {
    const dispatch = useDispatch();

    const walletUtils = useSelector((state) => get(state, 'utils.walletUtils', null));
    const isConnectWallet = useSelector((state) => get(state, 'utils.isConnectWallet', false));

    const [listTokens, setListTokens] = useState([])
    const [amount, setAmount] = useState(0);
    const [destinationAddress, setDestinationAddress] = useState("");
    const [inputNetwork, setInputNetwork] = useState(defaultInputNetwork);
    const [outputNetwork, setOutputNetwork] = useState(defaultOutputNetwork);
    const [tokenSelected, setTokenSelected] = useState({});

    const [isValidAddress, setIsValidAddress] = useState(false);
    const [enableSwapButton, setEnableSwapButton] = useState(false);
    const [currentNetWork, setCurrentNetWork] = useState("");
    const [isClearAmount, setIsClearAmount] = useState(false)
    const [pauseSwap, setPauSwap] = useState(true);


    const handleInputAmountChange = (value) => {
        setAmount(value);
    };

    //check inputNetWork
    useEffect(() => {
        if (walletUtils) {
            const network = walletUtils.getCurrentNetWork()
            setCurrentNetWork(NETWORK_LIST.find(e => e.id === network))
        }
    }, [inputNetwork, walletUtils])


    //check enable swap button
    useEffect(() => {
        if (walletUtils) {
            // const currentNetWork =  walletUtils.getCurrentNetWork()

            if (currentNetWork && tokenSelected) {
                if (Number(amount) > 0 && currentNetWork.id === inputNetwork.id) {
                    let { swapped, limit, networks } = tokenSelected;
                 
                    //check address is admin token
                    if(limit === 0 ) limit = LIMIT_VALUE.MAX

                    const bigAmount = BigNumber(`${amount}`)
                    const bigMax = BigNumber(`${limit - swapped}`);
                    if (bigAmount.gte(0) && bigAmount.lte(bigMax)) {
                        setEnableSwapButton(true)
                    } else {
                        setEnableSwapButton(false)
                    }
                    return;

                } else {
                    setEnableSwapButton(false)
                }
                //if token selected pause disable 
            }

        } else {
            setEnableSwapButton(false)
        }

    }, [amount, currentNetWork, walletUtils, inputNetwork, tokenSelected])

    const handleNetworkSwap = () => {
        const network = inputNetwork;
        setInputNetwork(outputNetwork);
        setOutputNetwork(network);
    };

    const handleInputNetworkChange = (value) => {
        // console.log("inputNetwork==>", value);
        if (value === outputNetwork) {
            handleNetworkSwap(value);
        } else {
            setInputNetwork(value);
        }
    };

    const handleOutputNetworkChange = (value) => {

        // console.log("outputNetwork==>", value);
        if (value === inputNetwork) {
            handleNetworkSwap(value);
        } else {
            setOutputNetwork(value);
        }
    };


    //handle select Token bridge
    const handleSelectedToken = (value) => {
        setTokenSelected(value)
    }

    //
    const handleOnchangeDestination = useCallback((value) => {
        setDestinationAddress(value);
    }, [])

    const handleCheckValidToAddress = (value) => {
        // console.log(value);
        setIsValidAddress(value)
    }


    //
    useEffect(() => {
        // console.log("network==>", inputNetwork.id);
        dispatch({
            type: ACTION_CONST.CURRENT_INPUT_NETWORK,
            data: inputNetwork.id
        })
        // debugger

        if (currentNetWork && currentNetWork.id !== inputNetwork.id) {
            dispatch({
                type: ACTION_CONST.ALERT_WARNING,
                message: `Wrong network You need connect to ${NETWORK_LIST.find(e => e.id == currentNetWork.id).name}!`
            })
        }

    }, [inputNetwork]);

    //get list token
    useEffect(() => {
        if (walletUtils)
            //get info pair token from smart contract 
            walletUtils.getInfo().then(data => {
                const listTokensTemp = data.tokens.filter(a =>
                    (
                        outputNetwork.id.toLocaleUpperCase() === 'BSC' &&
                            (a.networks.toLocaleUpperCase() === 'BSC' || a.networks.toLocaleUpperCase() === 'BSC2')
                    ) ||
                    (
                        outputNetwork.id.toLocaleUpperCase() !== 'BSC' &&
                        a.networks.toLocaleUpperCase() === outputNetwork.id.toLocaleUpperCase()
                    )
                );
                // debugger
                setListTokens(listTokensTemp);
                setPauSwap(data.paused)
            })
    }, [walletUtils, outputNetwork])

    //set default token
    useEffect(() => {
        
        if (listTokens.length > 0) {
            if (!tokenSelected.symbol)
                setTokenSelected(listTokens[0])
        }

    }, [listTokens])


    const handleClearAmount = useCallback(() => {
        setAmount(0);
        setIsClearAmount(true)

    }, [])

    const handleSwapped = (tokenAddress) => {
        // setIsSwappedSDone(tokenAddress)
        if (tokenAddress && walletUtils) {
            walletUtils.getInfo().then(data => {
                const listTokensTemp = data.tokens.filter(a =>
                    (
                        outputNetwork.id.toLocaleUpperCase() === 'BSC' &&
                            (a.networks.toLocaleUpperCase() === 'BSC' || a.networks.toLocaleUpperCase() === 'BSC2')
                    ) ||
                    (
                        outputNetwork.id.toLocaleUpperCase() !== 'BSC' &&
                        a.networks.toLocaleUpperCase() === outputNetwork.id.toLocaleUpperCase()
                    )
                );
                setListTokens(listTokensTemp);
                setPauSwap(data.paused)
                const defaultToken = data.tokens.find(
                    e => e.contractAddress === tokenAddress &&
                    e.networks === outputNetwork.id.toLocaleUpperCase()
                );
                setTokenSelected(defaultToken)
            })
        }
    }
    return (
        <>
            <Container>
                <Row>
                    <Column>
                        <Card title="">
                            {/* <div className="font-14 text-center">
                                The safe, fast and most secure way to bring
                                cross-chain assets to BSCPad chains.
                            </div> */}
                            <hr style={{ backgroundColor: 'rgba(255,255,255,.3)' }} />
                            {/* Panel Token DropDown */}
                            <AssetPanel tokenList={listTokens}
                                onChangeToken={handleSelectedToken}
                                tokenSelected={tokenSelected}
                                tokens={listTokens}
                            />

                            {/* Input NetWork Panel */}
                            <div className="p-bridge d-flex align-items-center">
                                <NetworkInputPanel
                                    type="in"
                                    label="From"
                                    networkList={NETWORK_LIST}
                                    selectedNetwork={inputNetwork}
                                    onNetworkChange={handleInputNetworkChange}
                                    isConnectWallet={isConnectWallet}
                                    connected={currentNetWork}
                                />
                                <div className="p-bridge-swap">
                                    <button disabled={isConnectWallet}
                                        type="button"
                                        className="btn btn-link text-warning btn-sm px-1 py-0"
                                        onClick={handleNetworkSwap}
                                    >
                                        <i className="mdi mdi-swap-horizontal font-24"></i>
                                    </button>
                                </div>
                                <NetworkInputPanel
                                    type="out"
                                    label="To"
                                    networkList={NETWORK_LIST}
                                    selectedNetwork={outputNetwork}
                                    onNetworkChange={handleOutputNetworkChange}
                                    connected={currentNetWork}
                                />
                            </div>

                            <AmountInputPanel
                                label="Amount"
                                onAmountChange={handleInputAmountChange}
                                network={inputNetwork}
                                token={tokenSelected}
                                isClearAmount={isClearAmount}
                                pauseSwap={pauseSwap}
                            />

                            {/* <AssetBalance token={tokenSelected} network={inputNetwork} /> */}

                            <DestinationInput
                                outputNetwork={outputNetwork}
                                inputNetwork={inputNetwork}
                                onDestinationChange={handleOnchangeDestination}
                                isValid={handleCheckValidToAddress}
                            />

                            <div className="mt-4 pt-1">
                                {
                                    !isConnectWallet ?
                                        <Button className="btn btn-primary btn-lg w-100"
                                            data-bs-toggle="modal" data-bs-target="#connectWalletModal"
                                        >Connect Wallet
                                        </Button>
                                        :
                                        <Button className="btn btn-primary btn-lg w-100"
                                            disabled={!enableSwapButton || !isValidAddress || pauseSwap || tokenSelected.paused}
                                            data-bs-toggle="modal" data-bs-target="#stepModal"
                                        >Swap
                                        </Button>
                                }
                            </div>
                            <div className="bottom-errors">
                                {(!isConnectWallet) && <div className="bottom-error text-center text-warning mt-3 font-14"><i className="mdi mdi-alert-outline me-1"></i>Please connect your wallet to swap.</div>}

                            </div>
                        </Card>
                    </Column>
                </Row>
            </Container>
            <StepModal amount={amount}
                token={tokenSelected}
                destination={destinationAddress}
                inputNetwork={currentNetWork}
                outputNetwork={outputNetwork}
                clearAmount={handleClearAmount}
                handleSwapped={handleSwapped}
            />
        </>
    );
};

export default BridgePortalPage;
