import React, { useEffect, useState } from 'react';
import WalletExtensionUtils from "../../utils/walletExtensionUtils";
import { extensionName } from "../../constants/values";
import {
    isMetamaskAvailable,
    isBinanceExtensionAvailable,
    isTrustWalletAvailable,
    isTronLinkAvailable
} from "../../utils/utils";
import { actAlertMsgWarning } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CONST, NETWORK_LIST } from '../../constants';
import { get } from 'lodash';
import { isMobile } from 'web3modal';
const defaultInputNetwork = NETWORK_LIST.find(e => e.defaultInput == true);



const ConnectWalletModal = (props) => {
    // const wallet = useWallet();
    const dispatch = useDispatch();
    //show hide button
    const [hasMetamask, setHasMetamask] = useState(false);
    const [hasTrustWallet, setHasTrustWallet] = useState(false);
    const [hasBinanceWallet, setHasBinanceWallet] = useState(false);
    const currentNetWork = useSelector((state) => get(state, "wallet.currentInputNetwork", defaultInputNetwork.id));
    const [hasTronLink, setHasTronLink] = useState(false);
    const [modalConnectTRXTitle, setModalTrxConnectTitle] = useState("")
    const [modalConnectBSCTitle, setModalBSCConnectTitle] = useState("")

    useEffect(() => {
        setTimeout(() => {
            addWallet();
        }, 3000);
    }, [currentNetWork]);

    const addWallet = () => {
        console.log("currentNetWork==>", currentNetWork);
        if (currentNetWork === 'BSC') {

            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(isBinanceExtensionAvailable());

        } else if (currentNetWork === 'ETH'|| currentNetWork === 'KCC') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(false);
        } else {
            setHasMetamask(false);
            setHasTrustWallet(false);
            setHasBinanceWallet(false);
        }
        if (currentNetWork === 'TRON') {
            setHasTronLink(isTronLinkAvailable());
        } else {
            setHasTronLink(false)
        }

    };


    useEffect(() => {
        if (isMobile() && !hasTronLink) {
            setModalTrxConnectTitle("Please open site in Tronlink app")
        }
        else {
            setModalTrxConnectTitle("Connect to wallet")
        }

        if (isMobile() && !hasBinanceWallet && !hasMetamask && !hasTrustWallet) {
            setModalBSCConnectTitle("Please open site in Wallet app")
        }
        else {
            setModalBSCConnectTitle("Connect to wallet")
        }

    }, [isMobile, hasTronLink, hasBinanceWallet, hasBinanceWallet, hasTrustWallet])



    useEffect(() => {
        // console.log("current network===>", currentNetWork);

        if (currentNetWork === 'BSC') {

            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(isBinanceExtensionAvailable());

        } else if (currentNetWork === 'ETH' || currentNetWork === 'KCC') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(false);
        }
        else {
            setHasMetamask(false)
            setHasTrustWallet(false);
            setHasBinanceWallet(false);
        }
        if (currentNetWork === 'TRON') {
            setHasTronLink(isTronLinkAvailable());
        } else {
            setHasTronLink(false)
        }



    }, [currentNetWork])



    const connectWithExtension = async (extension) => {

        if (extension === extensionName.tronLink && currentNetWork == 'BSC') {
            dispatch(
                actAlertMsgWarning(

                    `Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`

                )
            );
            return;
        }
        if ((extension === extensionName.metamask || extension === extensionName.binanceExtension || extension === extensionName.trustWallet) && currentNetWork == 'TRON') {

            dispatch(
                actAlertMsgWarning(

                    `Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`

                )
            );
            return;
        }

        const temp = new WalletExtensionUtils(extension);

        //Connect action
        await temp.connect(currentNetWork);
        // debugger
        if (temp.checkWrongNetwork()) {

            dispatch(
                actAlertMsgWarning(

                    `Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`

                )
            );
            return;
        }


        dispatch({
            type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
            data: temp
        })

        dispatch({
            type: ACTION_CONST.CURRENT_NET_WORK_EXTENSION,
            data: temp.getCurrentChainId()
        })


        // setIsSigning(extensionName);
        await getBalanceAndAddress(temp);

        // iff account exchange
        try {
            temp.accountsChanged(async (res) => {
                if (res !== undefined) {
                    // console.log('account changed')
                    dispatch({
                        type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                        data: temp
                    })
                    await getBalanceAndAddress(temp);

                }
            });

            return true;
        } catch (e) {
            dispatch({
                type: ACTION_CONST.REQUEST_DONE
            })
            console.log("error: ", e);
        }

        //if chain ID
        try {
            temp.chainChanged(async (chanId) => {

                await temp.connect(currentNetWork);
                if (temp.checkWrongNetwork()) {

                    dispatch(
                        actAlertMsgWarning(
                            "Wrong network! You need connect to Binance smart chain network!"
                        )
                    );
                    return;
                }
                dispatch({
                    type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                    data: temp
                })

                dispatch({
                    type: ACTION_CONST.CURRENT_NET_WORK_EXTENSION,
                    data: temp.getCurrentChainId()
                })

                await getBalanceAndAddress(temp);
            })
        } catch (error) {

        }

    };
    const getExtension = () => {
        return extensionName;
    };

    const getBalanceAndAddress = async (extension) => {

        const walletAddress = await extension.getCurrentAddress();


        dispatch({
            type: ACTION_CONST.CONNECT_WALLET_SUCCESS,
            data: walletAddress
        })


    };


    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">

                        {
                            currentNetWork === 'TRON' &&
                            <>
                                <div className="modal-header">
                                    <h6 className="modal-title text-dark" id="connectWalletModalLabel">{modalConnectTRXTitle}</h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body p-0">

                                    {
                                        hasTronLink &&
                                        <div className="c-list border-b px-3 py-2 d-flex align-items-center" data-bs-dismiss="modal"
                                            onClick={() => {
                                                connectWithExtension(
                                                    getExtension().tronLink
                                                )
                                            }}>
                                            <img src="/images/networks/tron-icon -white.svg" width="30px" className="me-2" alt="binance" />
                                            <div className="text-dark">
                                                TronLink
                                            </div>
                                        </div>
                                    }

                                </div>

                            </>
                        }
                        {(currentNetWork === 'BSC' || currentNetWork === 'ETH' || currentNetWork === 'KCC') &&
                            <>
                                <div className="modal-header">
                                    <h6 className="modal-title text-dark" id="connectWalletModalLabel">{modalConnectBSCTitle}</h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body p-0">
                                    {
                                        hasMetamask &&
                                        <div className="c-list border-b px-3 py-2 d-flex align-items-center" data-bs-dismiss="modal" onClick={() => {
                                            connectWithExtension(
                                                getExtension().metamask
                                            )
                                        }}>
                                            <img src="/images/metamask.svg" width="30px" className="me-2" alt="metamask" />
                                            <div className="text-dark">
                                                Metamask
                                            </div>
                                        </div>
                                    }
                                    {
                                        hasBinanceWallet &&
                                        <div className="c-list border-b px-3 py-2 d-flex align-items-center" data-bs-dismiss="modal"
                                            onClick={() => {
                                                connectWithExtension(
                                                    getExtension().binanceExtension
                                                )
                                            }}>
                                            <img src="/images/binance-extension.jpg" width="30px" className="me-2" alt="binance" />
                                            <div className="text-dark">
                                                Binance Chain Wallet
                                </div>
                                        </div>
                                    }

                                    {
                                        hasTrustWallet &&
                                        <div className="c-list border-b px-3 py-2 d-flex align-items-center" data-bs-dismiss="modal"
                                            onClick={() => {
                                                connectWithExtension(
                                                    getExtension().trustWallet
                                                )
                                            }}>
                                            <img src="/images/trust_platform.png" width="30px" className="me-2" alt="trust_platform" />
                                            <div className="text-dark">
                                                Trust Wallet
                                            </div>
                                        </div>
                                    }


                                </div>

                            </>

                        }


                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;


