import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import chainHost from "../../contracts/chainHost.json";
import Web3 from "web3";
const TronWeb = require('tronweb')

const tronWeb = new TronWeb({
    fullHost: chainHost.tronMainnet
}
)

export default function DestinationInput({ inputNetwork, outputNetwork, onDestinationChange, isValid }) {

    const isConnectWallet = useSelector((state) => get(state, 'utils.isConnectWallet', false));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", false));
    const walletUtils = useSelector((state) => get(state, 'utils.walletUtils', null));
    const [defaultValue, setDefaultValue] = useState("")
    const [readOnly, setReadOnly] = useState(false);
    const [isValidateAddress, setIsValidateAddress] = useState(false);
    const [isContractAddress, setIsContractAddress] = useState(false);


    useEffect(() => {
        // const bridgeNetwork = `${inputNetwork.id}_NETWORK`.toLocaleUpperCase();
        if (inputNetwork.type == outputNetwork.type) {
            // setReadOnly(true);
            setDefaultValue(walletAddress);
            onDestinationChange(walletAddress);
        } else {
            setDefaultValue("");
            setReadOnly(false);
        }
    }, [inputNetwork, onDestinationChange, outputNetwork, walletAddress]);

    const isContract = useCallback(async (network) => {
        const result = await walletUtils.isContract(defaultValue, network);
        setIsContractAddress(result)
        isValid(!result);
        console.log(`isContract: ${result}`)
    }, [defaultValue, walletUtils, isValid])

    useEffect(() => {
        let check = false;
        if (outputNetwork.type === "web3" ) {
            check = Web3.utils.isAddress(defaultValue)
            setIsValidateAddress(check)
        }
        if (outputNetwork.type === 'tron') {
            check = tronWeb.isAddress(defaultValue)
            setIsValidateAddress(check)
        }
        if (!isValidateAddress && check) {
            isContract(outputNetwork.id)
        }
        if (!isValidateAddress) {
            isValid(check)
        }
    }, [defaultValue, isContract, isValid, isValidateAddress, outputNetwork, outputNetwork.type, walletUtils])

    return (
        <>
            {
                isConnectWallet && <>
                    <label className="form-label p-main-text">Receiving address</label>
                    <div className={`p-form-group mb-2 ${(!isValidateAddress && defaultValue) ? 'p-control-invalid' : ''}`}>

                        <div className="p-input-group" style={{ position: 'relative' }}>
                            <img style={{ position: 'absolute', left: '0px', top: '7px' }} src={`/images/networks/${outputNetwork.id}-icon.svg`} height="18" />
                            <input style={{ fontSize: '14px' }}
                                onChange={(e) => {
                                    setIsValidateAddress(false)
                                    setIsContractAddress(false)
                                    setDefaultValue(e.target.value)
                                    onDestinationChange(e.target.value)
                                }
                                }
                                type="text"
                                className="form-control pe-0 ps-4 pb-0"

                                // defaultValue={defaultValue}
                                placeholder={`Input the receiving address on the ${outputNetwork.id.toLocaleUpperCase()} Network`}
                                readOnly={readOnly}
                                value={defaultValue||""}
                            />
                        </div>
                        {(!isValidateAddress && defaultValue) &&
                            <div className="p-invalid-address"><i className="mdi mdi-alert me-1"></i>Invalid address</div>
                        }
                        {(isValidateAddress && defaultValue && isContractAddress) &&
                            <div className="p-invalid-address"><i className="mdi mdi-alert me-1"></i>Cannot bridge to a contract address</div>
                        }
                    </div>
                </>
            }


        </>
    );
}
